<template>
  <div class="m-page">
    <img v-for="src in imgList" :key="src" :src="src" @click="handlePreview(src)" />
  </div>
</template>

<script>
import { ImagePreview } from 'vant'

export default {
  name: 'CloudExhibitionTransportation2023',

  data() {
    return {
      imgList: [
        'https://img.miaocang.cc/pcImg/cloud/2023-transportation-01.jpg',
        'https://img.miaocang.cc/pcImg/cloud/2023-transportation-02.jpg',
        'https://img.miaocang.cc/pcImg/cloud/2023-transportation-03.jpg',
        // [WIP]
        'https://img.miaocang.cc/7uw6f8polshw7dj6qacm.jpg'
      ]
    }
  },

  created() {
    document.title = '交通指南'
  },

  methods: {
    /* 大图预览 */
    handlePreview(src) {
      const { imgList } = this
      const startPosition = imgList.findIndex(item => item === src)
      ImagePreview({
        images: imgList,
        startPosition,
        closeable: true,
        showIndex: false,
        maxZoom: 10,
        getContainer() {
          return document.querySelector('.m-page')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-page {
  min-height: 100vh;
  text-align: center;
  background-color: #fff;
  img {
    width: 100vw;
    min-width: 320px;
    max-width: 640px;
  }
}
</style>